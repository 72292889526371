<template>
  <div class="order_no_details_box">
    <div class="order_heading">
      <h2>{{ $t("booking.order-no") }} : {{ orderNo }}</h2>
    </div>
    <div class="order_details_contain">
      <div class="d-flex justify-content-between">
        <div class="order_details_box">
          <ul>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon5.png`" width="15" height="15" alt="after-booking"/></span>
              {{ $t("booking.status") }} : <strong>{{ payer.status }}</strong>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon6.png`" width="15" height="15" alt="after-booking"/></span>
              {{ $t("booking.date") }} : <strong>{{ today }}</strong>
            </li>
          </ul>
        </div>
        <div class="order_details_box">
          <ul>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon2.png`" width="15" height="15" alt="after-booking"/></span>
              {{ $t("booking.name") }} : <strong>{{ name }}</strong>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon3.png`" width="15" height="15" alt="after-booking"/></span>
              {{ $t("booking.phone") }} : <strong>{{ mobile }}</strong>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon4.png`" width="15" height="15" alt="after-booking"/></span>
              {{ $t("booking.email") }} : <strong>{{ email }}</strong>
            </li>
          </ul>
        </div>
        <div class="order_details_box">
          <ul>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon1.png`" alt="after-booking" title="" width="15" height="15"/></span>
              {{ $t("booking.total-to-pay") }} : <strong>{{ price }}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    payer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      today: '01/01/2000',
      orderNo: 'N/A',
      status: 'ERROR',
      name: '',
      mobile: '',
      email: '',
      price: '0',
    };
  },
  watch: {
    payer() {
      this.updateInformation();
    },
  },
  created() {
    this.today = dayjs(new Date().toString()).format('DD/MM/YYYY');
    this.updateInformation();
  },
  methods: {
    updateInformation() {
      if (this.payer) {
        this.orderNo = this.payer.orderNo;
        this.status = this.payer.status;
        this.name = this.payer.name || `${this.payer.firstName} ${this.payer.lastName}`;
        this.mobile = this.payer.mobile || this.payer.phone;
        this.email = this.payer.email;
        this.price = this.payer.price;
      }
    },
  },
};
</script>

<style scoped>
.order_no_details_box {
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;
}
.order_no_details_box .order_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(39, 136, 210, 1) 0%,
    rgba(1, 98, 172, 1) 100%
  );
  padding: 15px 20px;
}
.order_no_details_box .order_heading h2 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}
.order_no_details_box .order_details_contain {
  padding: 25px 20px;
  background: #0763ab;
}
.order_no_details_box .order_details_contain .order_details_box {
  width: 100%;
  box-sizing: border-box;
  padding: 35px 15px;
  margin-left: 20px;
  background: #0c68ae;
  height: 180px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(7 80 138 / 60%);
}
.order_no_details_box .order_details_contain .order_details_box ul {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
}
.order_no_details_box .order_details_contain .order_details_box ul li {
  border-bottom: 1px solid #1774bb;
  padding-bottom: 8px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
}
.order_no_details_box .order_details_contain .order_details_box ul li .icon {
  margin-left: 12px;
}

@media (max-width: 479px) {
  .order_no_details_box .order_heading h2 {
    font-size: 20px;
  }
  .order_details_contain .d-flex {
    display: block !important;
  }
  .order_no_details_box .order_details_contain .order_details_box {
    padding: 15px 18px;
    margin-left: 0;
    height: auto;
    border-radius: 0;
    margin-bottom: 10px;
  }
}
</style>
